const subscribeNews = {
  methods: {
    /*evento do assinar news*/
    subscribeNews() {
      window.open("https://forms.gle/cQn2Kqk8PvzB2oQM9");
    }
  }
};

export default subscribeNews;
