const showCards = {
  data() {
    return {
      infos: this.$store.getters.cards,
      title: "",
      subtitle: "",
      keywords: "",
      author: "",
      datePub: "",
      timePub: "",
      desc: "",
      route: "",
      relevance: null,
      imgIcon: "",
      win: true
    };
  },
  computed: {
    cards(slice1, slice2) {
      slice1 = this.$store.getters.cards
        .filter(
          c => c.relevance === this.relevance || this.imgIcon === c.imgIcon
        )
        .slice(0, 2);

      slice2 = this.$store.getters.cards
        .filter(
          c => c.relevance === this.relevance || this.imgIcon === c.imgIcon
        )
        .slice(0, 3);

      return window.innerWidth <= 1023 ? slice1 : slice2;
    },

    latest(cards) {
      cards = this.$store.getters.cards.filter(
        r => r.route !== this.$route.name
      );

      cards.sort(function(a, b) {
        if (a.datePub > b.datePub) {
          return 1;
        }
        if (a.datePub < b.datePub) {
          return -1;
        }

        return 0;
      });

      return window.innerWidth <= 1023 ? cards.slice(0, 2) : cards.slice(0, 3);
    },
    research(research) {
      let r = this.$store.getters.cards.map(c => c.route);
      for (let routes = 0; routes < r.length; routes++) {
        if (r[routes] === this.$route.name) {
          return (research = this.$store.getters.cards[routes].research);
        }
      }
      return research;
    },

    /* card relacionado na home */
    cardRel() {
      return this.$store.getters.cards
        .filter(c => c.route !== "Artigo01")
        .slice(0, 2);
    }
  },
  created() {
    let r = this.$store.getters.cards.map(c => c.route);
    for (let routes = 0; routes < r.length; routes++) {
      if (r[routes] === this.$route.name) {
        this.title = this.infos[routes].title;
        this.subtitle = this.infos[routes].subtitle;
        this.imgIcon = this.infos[routes].imgIcon;
        this.author = this.infos[routes].author;
        this.keywords = this.infos[routes].keywords;
        this.datePub = this.infos[routes].datePub;
        this.timePub = this.infos[routes].timePub;
        this.desc = this.infos[routes].desc;
        this.relevance = this.infos[routes].relevance;
        this.route = this.infos[routes].route;
      }
    }

    if (window.innerWidth < 768) {
      this.win = false;
    }
  }
};
export default showCards;
